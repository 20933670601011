import React from 'react';
import { graphql } from 'gatsby';
import { mergePrismicPreviewData } from 'gatsby-source-prismic';
import { Layout, Slice } from 'components';

const IS_BROWSER = typeof window !== 'undefined';

export const Service = ({ location, data: staticData }) => {
  const previewData = IS_BROWSER && window.__PRISMIC_PREVIEW_DATA__;
  const data = mergePrismicPreviewData({ staticData, previewData });
  const { prismicService } = data;
  const { data: pageData } = prismicService;
  const {
    body: sliceData,
    meta_title: metaTitle,
    meta_description: metaDesc,
    open_graph_image: ogImage,
    schema,
    indexed,
  } = pageData;
  const seo = {
    title: metaTitle?.text,
    desc: metaDesc?.text,
    banner: ogImage?.url,
    schema: schema?.text,
    indexed,
  };
  return (
    <Layout location={location} seo={seo}>
      {sliceData?.map((slice) => (
        <Slice key={slice.id} data={slice} />
      ))}
    </Layout>
  );
};

export default Service;

export const serviceQuery = graphql`
  query ServiceBySlug($uid: String!) {
    prismicService(uid: { eq: $uid }) {
      id
      uid
      prismicId
      data {
        indexed
        title {
          text
        }
        category {
          uid
          type
        }
        meta_title {
          text
        }
        meta_description {
          text
        }
        open_graph_image {
          url
        }
        schema {
          text
        }
        body {
          ...PrismicServiceBodyHeadingFragment
          ...PrismicServiceBodyQuoteFragment
          ...PrismicServiceBodyTextFragment
          ...PrismicServiceBodyVideoFragment
          ...PrismicServiceBodyStatisticsFragment
          ...PrismicServiceBodyBannerFragment
          ...PrismicServiceBodyTestimonialFragment
          ...PrismicServiceBodyImagesFragment
          ...PrismicServiceBodyFeaturedProjectsFragment
          ...PrismicServiceBodyFeaturedServicesFragment
          ...PrismicServiceBodyFeaturedContentFragment
          ...PrismicServiceBodyFeaturedArticlesFragment
          ...PrismicServiceBodyQuickLinksFragment
          ...PrismicServiceBodyEbookFragment
        }
      }
    }
  }
`;
